import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import Flickity from 'react-flickity-component'
import { StyledCarouselContainer, StyledFlickityComponent } from './styledCardDesignCarousel'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { FlickityRefObject } from '../../types/FlickityRefObject'
import { useFlickityVisibilityFix } from '../../hooks/UseFlickityVisibilityFix'
import Equalizer from 'react-equalizer'

export type CardDesignCarouselProps = Omit<React.ComponentProps<typeof Flickity>, 'flickityRef'> & {
  columns?: 3 | 4
}

export const CardDesignCarousel: React.FC<CardDesignCarouselProps> = ({ children, columns = 3, ...props }) => {
  const flickityRef = useRef(null) as FlickityRefObject
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const totalItems = React.Children.toArray(children).length

  useFlickityVisibilityFix(flickityRef)

  if (totalItems === 0) {
    return null
  }

  const groupCells = useMemo(() => {
    return !isDesktop ? 1 : (isTablet ? 2 : columns)
  }, [isDesktop, isTablet, columns])

  const groupSize = useMemo(() => {
    return totalItems >= groupCells ? groupCells : totalItems
  }, [groupCells, totalItems])

  useEffect(() => {
    if (flickityRef.current?.flkty && flickityRef.current.flkty?.options.groupCells !== groupCells) {
      flickityRef.current.flkty.options.groupCells = groupCells
      flickityRef.current.flkty.resize()
    }
  }, [flickityRef, groupCells])

  const getNodes = useCallback((component: React.ComponentType, node: Element) => {
    return node.querySelectorAll('[data-same-height]')
  }, [])

  return (
    <StyledCarouselContainer>
      <Equalizer nodes={getNodes}>
        <StyledFlickityComponent {...props}
          ref={flickityRef}
          $columns={groupSize}
          $hasNavigation={totalItems > groupCells}
          options={{
            cellAlign: 'center',
            adaptiveHeight: true,
            freeScroll: false,
            groupCells: groupCells,
            wrapAround: true,
          }}>
          {children}
        </StyledFlickityComponent>
      </Equalizer>
    </StyledCarouselContainer>
  )
}
